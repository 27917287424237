import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
// import VideoBroadcast from './components/VideoBroadcast';
// import LiveStreams from './components/LiveStreams';
// import LiveStreamBroadcaster from './components/LiveStreamBroadcaster';
// import LiveStreamPlayer from './components/LiveStreamPlayer';
// import Chats from './components/Chats';
// import Admin from './components/Admin';

import "./App.css";

Amplify.configure(awsconfig);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
// const TheLayout = React.lazy(() => import('./containers/TheLayout'));
// const Admin = React.lazy(() => import("./components/Admin"));
const LiveStreams = React.lazy(() => import("./components/LiveStreams"));
// const LiveStreamBroadcaster = React.lazy(() =>
//   import("./components/LiveStreamBroadcaster")
// );
// const LiveStreamPlayer = React.lazy(() =>
//   import("./components/LiveStreamPlayer")
// );
const LiveStreamBroadcaster = React.lazy(() =>
  import("./components/agora/LiveStreamBroadcaster")
);
const LiveStreamPlayer = React.lazy(() =>
  import("./components/agora/LiveStreamPlayer")
);

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'));
// const Register = React.lazy(() => import('./views/pages/register/Register'));
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

function App() {
  return (
    <div className="App">
      <Router>
        <React.Suspense fallback={loading}>
          <Switch>
            {/* <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} /> */}
            {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
            {/* <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} /> */}
            {/* <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} /> */}
            {/* <Route path="/" name="Home" render={props => <TheLayout {...props}/>} /> */}
            <Route
              path="/"
              exact={true}
              name="Home"
              render={(props) => <LiveStreams {...props} />}
            />
            <Route
              path="/broadcast/:liveStreamId"
              name="Livestream Broadcasting"
              render={(props) => <LiveStreamBroadcaster {...props} />}
            />
            <Route
              path="/livestreams/:liveStreamId"
              name="Livestream"
              render={(props) => <LiveStreamPlayer {...props} />}
            />
            {/* <Route
              path="/agora/broadcast/:liveStreamId"
              name="Livestream Broadcasting"
              render={(props) => <AgoraLiveStreamBroadcaster {...props} />}
            />
            <Route
              path="/agora/livestreams/:liveStreamId"
              name="Livestream"
              render={(props) => <AgoraLiveStreamPlayer {...props} />}
            /> */}
          </Switch>
        </React.Suspense>
      </Router>
    </div>
    // <div className="App">
    //   {/* <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header> */}
    //   {/* <VideoBroadcast /> */}
    //   <LiveStreams />
    //   {/* <Chats /> */}
    // </div>
  );
}

export default App;
